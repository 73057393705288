export const outputType = [
    {label: "Currency", value: 'CURRENCY'},
    {label: "Number", value: 'NUMBER'},
    {label: "Percent", value: 'PERCENT'},
    {label: "Free", value: 'FREE'},
]
export const allTypes = [
    {label: "Workloads", value: 'WORKLOAD'},
    {label: "Free", value: 'FREE'},
]
export const operators = [
    {label: "Addition", value: 'ADD', icon: <span>+</span>, type: "Operators"},
    {label: "Subtraction", value: 'SUBTRACT', icon: <span>−</span>, type: "Operators"},
    {label: "Multiply", value: 'MULTIPLY', icon: <span>×</span>, type: "Operators"},
    {label: "Division", value: 'DIVIDE', icon: <span>÷</span>, type: "Operators"},
    {label: "IF Boolean", value: 'IF_BOOLEAN', icon: <span className="text">IF</span>, type: "Operators"},
    {label: "IF Compare", value: 'IF_COMPARE', icon: <span>&gt;</span>, type: "Operators"},
    {label: "Free", value: 'DIRECT_VALUE'},
]
export const conditionalOperators = [
    {label: "Greater Than", value: 'GREATER_THAN', icon: <span>&gt;</span>, type: "Operators"},
    {label: "Less Than", value: 'LESS_THAN', icon: <span>&lt;</span>, type: "Operators"},
    {label: "Equals too", value: 'EQUAL_TO', icon: <span>=</span>, type: "Operators"},
    {label: "Not Equals too", value: 'NOT_EQUAL_TO', icon: <span>&lt;&gt;</span>, type: "Operators"},
]
export const menuItems = [
    {label: 'Global Parameters', value: 'GLOBAL_PARAMETER', type: 'DIRECT_VALUE'},
    {label: 'Workload', value: 'WORKLOAD', type: 'DIRECT_VALUE'},
    {label: 'Characteristics', value: 'CHARACTERISTIC', type: 'DIRECT_VALUE'},
    {label: 'Roles', value: 'ROLE', type: 'DIRECT_VALUE'},
    {label: 'Drivers', value: 'DRIVER', type: 'DIRECT_VALUE'},
    {label: 'Calendar', value: 'CALENDAR', type: 'DIRECT_VALUE'},
    {label: 'Free', value: 'FREE', type: 'DIRECT_VALUE'},
    {label: 'Operation', value: 'OPERATOR', type: 'OPERATOR'},
]
export const taskFieldsName = [
    {label: "Basic Minutes", value: "basicMinutes", type: 'last'},
    {label: "Relaxation Allowance", value: "relaxationAllowance", type: 'last'},
    {label: "Contingency Allowance", value: "contingencyAllowance", type: 'last'},
    {label: "Standard Minute Value", value: "standardMinuteValue", type: 'last'},
]

export const groupDetails = [
    {
        "_id": "6777b0908863018312614968",
        "type": "WORKLOADS",
        "groupCount": 1,
        "name": "UAT",
        "groupLetter": "A",
        "rows": [
            {
                "_id": "6777b090462c475e0cf80ff0",
                "name": "Task First",
                "rowId": "A1",
                "outputType": "NUMBER",
                "fineTuning": false,
                "error": 'The Role Sales Asscociate has been removed',
                "formula": {
                    "type": "MULTIPLY",
                    "variable": [
                        {
                            "openMenu": null,
                            "type": "SUBTRACTION",
                            "variable": [
                                {
                                    "openMenu": null,
                                    "type": "MULTIPLY",
                                    "variable": [
                                        {
                                            "openMenu": null,
                                            "type": "DIRECT_VALUE",
                                            "source": {
                                                "type": "ROLE",
                                                "path": [
                                                    "ROLE",
                                                    {
                                                        "label": "Sales Associate",
                                                        "value": "67625f78f95787abdb37b0c3"
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            "openMenu": null,
                                            "type": "DIRECT_VALUE",
                                            "source": {
                                                "type": "WORKLOAD",
                                                "path": [
                                                    "WORKLOAD",
                                                    {
                                                        "label": "UAT",
                                                        "value": "675c08b6f95787abdb37af8b"
                                                    },
                                                    {
                                                        "label": "Task Second",
                                                        "value": "675c0882f95787abdb37af71"
                                                    },
                                                    {
                                                        "label": "Basic Minutes",
                                                        "value": "basicMinutes"
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                },
                                {
                                    "openMenu": null,
                                    "type": "DIRECT_VALUE",
                                    "source": {
                                        "type": "CALENDAR",
                                        "path": [
                                            "CALENDAR",
                                            {
                                                "label": "Sick",
                                                "value": "675c09f7f95787abdb37afbf"
                                            }
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "openMenu": null,
                            "type": "MULTIPLY",
                            "variable": [
                                {
                                    "openMenu": null,
                                    "type": "DIRECT_VALUE",
                                    "source": {
                                        "type": "DRIVER",
                                        "path": [
                                            "DRIVER",
                                            {
                                                "label": "Till",
                                                "value": "675c0996f95787abdb37afa6"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "openMenu": null,
                                    "type": "DIRECT_VALUE",
                                    "source": {
                                        "type": "DRIVER",
                                        "path": [
                                            "DRIVER",
                                            {
                                                "label": "Till",
                                                "value": "675c0996f95787abdb37afa6"
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    ]
                },
                "isSubChild": true
            },
            {
                "_id": "6777b0902e583320f75e07d6",
                "name": "Task Second",
                "rowId": "A2",
                "outputType": "NUMBER",
                "fineTuning": false,
                "formula": {}
            },
            {
                "_id": "6777b09022d240a51b67eff5",
                "name": "Group Subtotal",
                "rowId": "A3",
                "type": "subtotal",
                "fineTuning": false,
                "outputType": "NUMBER",
                "formula": {}
            }
        ],
        "taskOptions": [
            {
                "label": "Task First",
                "value": "Task First"
            },
            {
                "label": "Task Second",
                "value": "Task Second"
            }
        ]
    }
]