import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { menuItems, operators, taskFieldsName } from './constant';
import TextInput from '../../../../components/TextInput';
import CustomTypography from '../../../../components/CustomTypography';
import { replaceUnderscoresWithSpace } from '../../../../utils/helper';

const VariableDropdown = ({handleCloseMenu, type, parentVariableIndex, data, index, rowindex, anchorEl, open, className, modelData, childVariableIndex, varIndex, subChildVariableIndex}) => {
  const [group, setGroup] = data;
  const [menuOptions, setMenuOptions] = useState([...menuItems]);
  const [filteredMenuOptions, setFilteredMenuOptions] = useState([]);
  const [optionSearch, setOptionSearch] = useState('');
  const validTypes = ["last", "GLOBAL_PARAMETER", "WORKLOAD", "tasks", "Operators"];
  const formula = group?.[index]?.rows?.[rowindex]?.formula;

    const getParentVariable = () => {
        return formula.variable?.[parentVariableIndex]
    }

    const getChildVariable = () => {
        return getParentVariable().variable?.[childVariableIndex]
    }

    const getSubChildVariable = () => {
        return getChildVariable().variable?.[subChildVariableIndex]
    }

  const changeDropDownOptions = (val, itemType) => {
    let options = [];
    if(itemType === "OPERATOR"){
      options = operators.filter((item)=> item.value !== "DIRECT_VALUE");
    } 
    else if(itemType === 'DIRECT_VALUE') {
      if(val === "GLOBAL_PARAMETER"){
        options = modelData?.parameterGroups?.map((parameterGroup)=> ({label: parameterGroup.name, value: parameterGroup._id, type: val}))
      } else if(val === "WORKLOAD"){
        options = modelData?.workgroup?.workloads?.map((workload)=> ({label: workload.name, value: workload._id, type: val}))
      } else if(val === "CHARACTERISTIC"){
        options = modelData?.hierarchy?.characteristicColumns?.filter((char) => {
            return char.id !== "no" && char.id !== "name" && char.id !== "newCharacteristics";
          })?.map((char) => {
            return { label: char.label, value: char.id, type: 'last' };
          });
      } else if(val === "ROLE"){
        options = modelData?.role?.groups?.map((role)=> ({label: role.name, value: role._id, type: 'last'}))
      } else if(val === "DRIVER"){
        options = modelData?.driver?.groups?.map((driver)=> ({label: driver.name, value: driver._id, type: 'last'}))
      } else if(val === "CALENDAR"){
        options = modelData?.calendar?.rows?.map((calendar)=> ({label: calendar.name, value: calendar._id, type: 'last'}))
      }
    } else if(itemType === 'GLOBAL_PARAMETER'){
      const parameter = modelData?.parameterGroups?.find((param)=> param._id === val)
      options = parameter?.parameters?.map((param)=> ({label: param.name, value: param._id, type: 'last'}))
    } else if(itemType === 'WORKLOAD'){
      const workloads = modelData?.workgroup?.workloads?.find((workload)=> workload._id === val)
      options = workloads?.tasks?.map((task)=> ({label: task.name, value: task._id, type: 'tasks'}))
    } else if(itemType === 'tasks'){
      options = taskFieldsName;
    }
    if(options?.length > 0){
      setMenuOptions([...options]);
    } else{
      setMenuOptions([{label: 'No data found', value: '', type: 'last'}]);
    }
  }

  const handleMenuItemClick = (menuItem) => {
    let groupData = group;
    if(type === 'parent'){
      if(menuItem.type && menuItem.type === 'DIRECT_VALUE'){
        if(formula?.type === "DIRECT_VALUE"){
          groupData[index].rows[rowindex].formula = {
            ...groupData[index].rows[rowindex].formula,
            type: menuItem.type,
            source: {
              type: menuItem.value,
              path: menuItem.value === 'FREE' ? [""]: [menuItem.value]
            }
          }
        } else {
          groupData[index].rows[rowindex].formula.variable[parentVariableIndex] = {
            ...groupData[index].rows[rowindex].formula.variable[parentVariableIndex],
            type: menuItem.type,
            source: {
              type: menuItem.value,
              path: menuItem.value === 'FREE' ? [""]: [menuItem.value]
            }
          }
        }
        changeDropDownOptions(menuItem.value, menuItem.type);
      } else if(menuItem.type === "GLOBAL_PARAMETER" || menuItem.type === "WORKLOAD" || menuItem.type === "tasks"){
        if(formula?.type === "DIRECT_VALUE"){
          groupData[index].rows[rowindex].formula.source = {
            ...groupData[index].rows[rowindex].formula.source,
            path: [...groupData[index].rows[rowindex].formula.source.path, {label: menuItem.label, value: menuItem.value}]
          }
        } else {
          groupData[index].rows[rowindex].formula.variable[parentVariableIndex].source = {
            ...groupData[index].rows[rowindex].formula.variable[parentVariableIndex].source,
            path: [...groupData[index].rows[rowindex].formula.variable[parentVariableIndex].source.path, {label: menuItem.label, value: menuItem.value}]
          }
        }
        changeDropDownOptions(menuItem.value, menuItem.type);
      } else if(menuItem.type === "last"){
        if(formula?.type === "DIRECT_VALUE"){
          groupData[index].rows[rowindex].formula.source = {
            ...groupData[index].rows[rowindex].formula.source,
            path: [...groupData[index].rows[rowindex].formula.source.path, {label: menuItem.label, value: menuItem.value}]
          }
        } else {
          groupData[index].rows[rowindex].formula.variable[parentVariableIndex].source = {
            ...groupData[index].rows[rowindex].formula.variable[parentVariableIndex].source,
            path: [...groupData[index].rows[rowindex].formula.variable[parentVariableIndex].source.path, {label: menuItem.label, value: menuItem.value}]
          }
        }
        handleCloseMenu(parentVariableIndex, type)
        setMenuOptions([...menuItems])
      } else if(menuItem.type === "OPERATOR"){
        if(formula?.type === "DIRECT_VALUE"){
          const {source: _, ...newObj} = groupData[index].rows[rowindex].formula; // eslint-disable-line no-unused-vars
          groupData[index].rows[rowindex].formula = newObj;
        } else {
          const {source: _, ...newObj} = groupData[index].rows[rowindex].formula.variable[parentVariableIndex]; // eslint-disable-line no-unused-vars
          groupData[index].rows[rowindex].formula.variable[parentVariableIndex] = newObj;
        }
        changeDropDownOptions(menuItem.value, menuItem.type);
      } else if(menuItem.type === "Operators"){
        if(formula?.type === "DIRECT_VALUE"){
          groupData[index].rows[rowindex].formula.type = menuItem.value;
          groupData[index].rows[rowindex].formula.variable = [{openMenu: null}, {openMenu: null}]
        } else if(menuItem.value === "IF_BOOLEAN") {
          groupData[index].rows[rowindex].formula.variable[parentVariableIndex].type = menuItem.value;
          groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable = [{openMenu: null}, {openMenu: null}, {openMenu: null}]
        } else if(menuItem.value === "IF_COMPARE") {
          groupData[index].rows[rowindex].formula.variable[parentVariableIndex].type = menuItem.value;
          groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable = [
            { openMenu: null },
            {
              openMenu: null,
              type: 'DIRECT_VALUE',
              source: {
                type: 'OPERATOR',
                path: ['OPERATOR', 'GREATER_THAN'],
              },
            },
            { openMenu: null },
            { openMenu: null },
            { openMenu: null },
          ]
        } else {
          groupData[index].rows[rowindex].formula.variable[parentVariableIndex].type = menuItem.value;
          groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable = [{openMenu: null}, {openMenu: null}]
        }
        handleCloseMenu(parentVariableIndex, type)
        setMenuOptions([...menuItems])
      }
    } else if(type === 'child'){
      if(menuItem.type && menuItem.type === 'DIRECT_VALUE'){
        groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex] = {
          ...groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex],
          type: menuItem.type,
          source: {
            type: menuItem.value,
            path: menuItem.value === 'FREE' ? [""]: [menuItem.value]
          }
        }
        changeDropDownOptions(menuItem.value, menuItem.type);
      } else if(menuItem.type === "GLOBAL_PARAMETER" || menuItem.type === "WORKLOAD" || menuItem.type === "tasks"){
        groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].source = {
          ...groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].source,
          path: [...groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].source.path, {label: menuItem.label, value: menuItem.value}]
        }
        changeDropDownOptions(menuItem.value, menuItem.type);
      } else if(menuItem.type === "last"){
        groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].source = {
          ...groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].source,
          path: [...groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].source.path, {label: menuItem.label, value: menuItem.value}]
        }
        handleCloseMenu(parentVariableIndex, type, childVariableIndex)
        setMenuOptions([...menuItems])
      } else if(menuItem.type === "OPERATOR"){
        changeDropDownOptions(menuItem.value, menuItem.type);
        const {source: _, ...newObj} = groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex]; // eslint-disable-line no-unused-vars
        groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex] = newObj;
      } else if(menuItem.type === "Operators"){
        groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].type = menuItem.value;
        groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].variable = [{openMenu: null}, ...(menuItem.value === "IF_COMPARE" ? [{openMenu: null,type: 'DIRECT_VALUE',source: {type: 'OPERATOR',path: ['OPERATOR', 'GREATER_THAN']}}, { openMenu: null }] : []), {openMenu: null}, ...((menuItem.value === "IF_BOOLEAN" || menuItem.value === "IF_COMPARE") ? [{ openMenu: null }] : [])]
        groupData[index].rows[rowindex] = {
          ...groupData[index].rows[rowindex],
          isSubChild: true
        }
        handleCloseMenu(parentVariableIndex, type, childVariableIndex)
        setMenuOptions([...menuItems])
      }
    } else if(type === 'sub-child'){
      if(menuItem.type && menuItem.type === 'DIRECT_VALUE'){
        groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].variable[subChildVariableIndex] = {
          ...groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].variable[subChildVariableIndex],
          type: menuItem.type,
          source: {
            type: menuItem.value,
            path: menuItem.value === 'FREE' ? [""]: [menuItem.value]
          }
        }
        changeDropDownOptions(menuItem.value, menuItem.type);
      } else if(menuItem.type === "GLOBAL_PARAMETER" || menuItem.type === "WORKLOAD" || menuItem.type === "tasks"){
        groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].variable[subChildVariableIndex].source = {
          ...groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].variable[subChildVariableIndex].source,
          path: [...groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].variable[subChildVariableIndex].source.path, {label: menuItem.label, value: menuItem.value}]
        }
        changeDropDownOptions(menuItem.value, menuItem.type);
      } else if(menuItem.type === "last"){
        groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].variable[subChildVariableIndex].source = {
          ...groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].variable[subChildVariableIndex].source,
          path: [...groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].variable[subChildVariableIndex].source.path, {label: menuItem.label, value: menuItem.value}]
        }
        handleCloseMenu(parentVariableIndex, type, childVariableIndex, subChildVariableIndex)
        setMenuOptions([...menuItems])
      }
    }
    if(menuItem.value === 'FREE'){
      handleCloseMenu(parentVariableIndex, type, childVariableIndex, subChildVariableIndex)
      setMenuOptions([...menuItems])
    }
    setGroup([...groupData]);
    setOptionSearch('')
  }

  const handleBack = () => {
    let options = [];
    let groupData = group;
    if(type === 'parent' && formula.type === "DIRECT_VALUE" ){
      if(formula.source.path.length === 1){
        options = menuItems.filter((item)=> item.value !== "OPERATOR");
        groupData[index].rows[rowindex].formula.source = {}
      } else if(formula.source.path.length === 2) {
        if(formula.source.type === "GLOBAL_PARAMETER"){
          options = modelData?.parameterGroups?.map((parameterGroup)=> ({label: parameterGroup.name, value: parameterGroup._id, type: 'GLOBAL_PARAMETER'}));
        } else if(formula.source.type === "WORKLOAD"){
          options = modelData?.workgroup?.workloads?.map((workload)=> ({label: workload.name, value: workload._id, type: 'WORKLOAD'}))
        }
        groupData[index].rows[rowindex].formula.source.path = [groupData[index].rows[rowindex].formula.source.path[0]]
      } else if(formula.source.path.length === 3) {
        const workloads = modelData?.workgroup?.workloads?.find((workload)=> workload._id === groupData[index].rows[rowindex].formula.source.path[1].value)
        options = workloads?.tasks?.map((task)=> ({label: task.name, value: task._id, type: 'tasks'}))
        groupData[index].rows[rowindex].formula.source.path = [groupData[index].rows[rowindex].formula.source.path[0], groupData[index].rows[rowindex].formula.source.path[1]]
      }
    }
    else if(type === 'parent'){
      if(!getParentVariable()?.type || !getParentVariable()?.source){
        options = [...menuItems]
      } else if(getParentVariable().source.path.length === 1){
        options = [...menuItems]
        groupData[index].rows[rowindex].formula.variable[parentVariableIndex].source = {}
      } else if(getParentVariable().source.path.length === 2) {
        if(getParentVariable().source.type === "GLOBAL_PARAMETER"){
          options = modelData?.parameterGroups?.map((parameterGroup)=> ({label: parameterGroup.name, value: parameterGroup._id, type: 'GLOBAL_PARAMETER'}));
        } else if(getParentVariable().source.type === "WORKLOAD"){
          options = modelData?.workgroup?.workloads?.map((workload)=> ({label: workload.name, value: workload._id, type: 'WORKLOAD'}))
        }
        groupData[index].rows[rowindex].formula.variable[parentVariableIndex].source.path = [groupData[index].rows[rowindex].formula.variable[parentVariableIndex].source.path[0]]
      } else if(getParentVariable().source.path.length === 3) {
        const workloads = modelData?.workgroup?.workloads?.find((workload)=> workload._id === groupData[index].rows[rowindex].formula.variable[parentVariableIndex].source.path[1].value)
        options = workloads?.tasks?.map((task)=> ({label: task.name, value: task._id, type: 'tasks'}))
        groupData[index].rows[rowindex].formula.variable[parentVariableIndex].source.path = [groupData[index].rows[rowindex].formula.variable[parentVariableIndex].source.path[0], groupData[index].rows[rowindex].formula.variable[parentVariableIndex].source.path[1]]
      }
    } else if(type === 'child'){
      if(!getChildVariable()?.type || !getChildVariable()?.source){
        options = [...menuItems]
      } else if(getChildVariable().source.path.length === 1){
        options = [...menuItems]
        groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].source = {}
      } else if(getChildVariable().source.path.length === 2) {
        if(getChildVariable().source.type === "GLOBAL_PARAMETER"){
          options = modelData?.parameterGroups?.map((parameterGroup)=> ({label: parameterGroup.name, value: parameterGroup._id, type: 'GLOBAL_PARAMETER'}));
        } else if(getChildVariable().source.type === "WORKLOAD"){
          options = modelData?.workgroup?.workloads?.map((workload)=> ({label: workload.name, value: workload._id, type: 'WORKLOAD'}))
        }
        groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].source.path = [groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].source.path[0]]
      } else if(getChildVariable().source.path.length === 3) {
        const workloads = modelData?.workgroup?.workloads?.find((workload)=> workload._id === groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].source.path[1].value)
        options = workloads?.tasks?.map((task)=> ({label: task.name, value: task._id, type: 'tasks'}))
        groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].source.path = [groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].source.path[0], groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].source.path[1]]
      }
    } else if(type === 'sub-child'){
      if(!getSubChildVariable()?.type || !getSubChildVariable()?.source){
        options = menuItems.filter((item)=> item.value !== "OPERATOR");
      } else if(getSubChildVariable().source.path.length === 1){
        options = menuItems.filter((item)=> item.value !== "OPERATOR");
        groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].variable[subChildVariableIndex].source = {}
      } else if(getSubChildVariable().source.path.length === 2) {
        if(getSubChildVariable().source.type === "GLOBAL_PARAMETER"){
          options = modelData?.parameterGroups?.map((parameterGroup)=> ({label: parameterGroup.name, value: parameterGroup._id, type: 'GLOBAL_PARAMETER'}));
        } else if(getSubChildVariable().source.type === "WORKLOAD"){
          options = modelData?.workgroup?.workloads?.map((workload)=> ({label: workload.name, value: workload._id, type: 'WORKLOAD'}))
        }
        groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].variable[subChildVariableIndex].source.path = [groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].variable[subChildVariableIndex].source.path[0]]
      } else if(getSubChildVariable().source.path.length === 3) {
        const workloads = modelData?.workgroup?.workloads?.find((workload)=> workload._id === groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].variable[subChildVariableIndex].source.path[1].value)
        options = workloads?.tasks?.map((task)=> ({label: task.name, value: task._id, type: 'tasks'}))
        groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].variable[subChildVariableIndex].source.path = [groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].variable[subChildVariableIndex].source.path[0], groupData[index].rows[rowindex].formula.variable[parentVariableIndex].variable[childVariableIndex].source.path[1]]
      }
    }
    setMenuOptions([...options]);
    setOptionSearch('')
  }

  const getModelTitle = () => {
    if(type === 'parent'){
      if(formula.type === "DIRECT_VALUE"){
        return replaceUnderscoresWithSpace(group[index].rows[rowindex].formula.source.type )
      } else{
        return getParentVariable()?.source ? replaceUnderscoresWithSpace(getParentVariable()?.source?.type) : "OPERATORS"
      }
    } else if(type === 'child'){
      return getChildVariable()?.source ? replaceUnderscoresWithSpace(getChildVariable()?.source?.type) : "OPERATORS"
    } else if(type === 'sub-child'){
      return getSubChildVariable()?.source ? replaceUnderscoresWithSpace(getSubChildVariable()?.source?.type) : "OPERATORS"
    }
  }

  useEffect(()=>{
    setFilteredMenuOptions([...menuOptions]);
  }, [menuOptions])

  useEffect(()=>{
    const options = menuOptions.filter((option)=>(option.label.toLowerCase().includes(optionSearch.toLowerCase())))
    setFilteredMenuOptions([...options])
  }, [optionSearch])

  useEffect(()=>{
    if(group[index].rows[rowindex].formula.type === "DIRECT_VALUE"){
      let options = menuOptions.filter((item)=> item.value !== "OPERATOR");
      setMenuOptions(options);
    }
  }, [open])

  useEffect(()=>{
    if(Number.isInteger(subChildVariableIndex)){
      let options = menuOptions.filter((opt) => opt.value !== "OPERATOR");
      setMenuOptions(options);
    }
  }, [group])

  return (
    <Menu
      id={`${type === 'parent' ? "menu" : type === 'child' ? "child_menu" : type === 'sub-child' ? "sub_child_menu" : ''}_variable_${varIndex}`}
      anchorEl={anchorEl}
      open={open}
      onClose={()=> {handleCloseMenu(parentVariableIndex, type, childVariableIndex, subChildVariableIndex); setMenuOptions([...menuItems])}}
      MenuListProps={{
          "aria-labelledby": `${type === 'parent' ? "field" : type === 'child' ? "child_field" : type === 'child' ? "sub_child_field" : ''}_variable_${varIndex}`
      }}
      className={className}
      disableAutoFocusItem
      disableAutoFocus
      disableRestoreFocus
    >
      {
        menuOptions.length > 0 ?
        <>
          {
            menuOptions.some(menuItem => validTypes.includes(menuItem.type)) &&
            <Box className="menu-head">
              <Box>
                <IconButton aria-label="back" onClick={handleBack}>
                  <ArrowBackRoundedIcon />
                </IconButton>
                <p>{getModelTitle()}</p>
              </Box>
              <TextInput
              name="search-field"
              value={optionSearch}
              onInputChange={(e) => setOptionSearch(e.target.value)}
              placeholder="Search"
              onKeyDown={(e) => e.stopPropagation()} 
              sizeSmall />
            </Box>
          }
          {
            filteredMenuOptions.length > 0 ?
            <>
              {filteredMenuOptions.map((menuItem)=>(
                  <MenuItem key={menuItem.value} disabled={!menuItem.value} onClick={()=> handleMenuItemClick(menuItem)}>{menuItem.label}</MenuItem>
              ))}
            </>
            : <CustomTypography variant="subtitle3" text='No items found' className="no-item-text" />
          }
        </>
        :
        <Box className="menu-head">
          <Box>
            <IconButton aria-label="back" onClick={handleBack}>
              <ArrowBackRoundedIcon />
            </IconButton>
            <p>{getModelTitle()}</p>
          </Box>
          <CustomTypography variant="subtitle3" text='No items found' className="no-item-text" />
        </Box>
      }
    </Menu>
  )
}

export default VariableDropdown