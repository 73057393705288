 import { createSlice } from '@reduxjs/toolkit';
import { createApiThunk } from '../../utils/asyncThunk';

export const fetchModelList = createApiThunk('fetchModelList');
export const getStepsInfo = createApiThunk('getStepsInfo');
export const filterModels = createApiThunk('filterModels');
export const fetchModel = createApiThunk('fetchModel');
export const addModel = createApiThunk('addModel');
export const changeModelStatus = createApiThunk('changeModelStatus');
export const getHierarchy = createApiThunk('getHierarchy');
export const getTasks = createApiThunk('getTasks');
export const getWorkgroups = createApiThunk('getWorkgroups');
export const getRoles = createApiThunk('getRoles');
export const getDrivers = createApiThunk('getDrivers');
export const getCalendars = createApiThunk('getCalendars');
export const getCalculations = createApiThunk('getCalculations');
export const getFT = createApiThunk('getFT'); 
export const updateFT = createApiThunk('updateFT');

const modelSlice = createSlice({
  name: 'model',
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  extraReducers: (builder) => {
    // Fetch Models
    builder.addCase(fetchModelList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchModelList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchModelList.rejected, (state) => {
      state.isError = true;
    });

    // Filter Models
    builder.addCase(filterModels.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(filterModels.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(filterModels.rejected, (state) => {
      state.isError = true;
    });

    // Fetch Single Model
    builder.addCase(fetchModel.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchModel.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchModel.rejected, (state) => {
      state.isError = true;
    });

    // Add Model
    builder.addCase(addModel.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addModel.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(addModel.rejected, (state) => {
      state.isError = true;
    });

    // Change Model Status
    builder.addCase(changeModelStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeModelStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(changeModelStatus.rejected, (state) => {
      state.isError = true;
    });

    // Get Hierarchy
    builder.addCase(getHierarchy.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getHierarchy.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getHierarchy.rejected, (state) => {
      state.isError = true;
    });
  },
});

export default modelSlice.reducer;
