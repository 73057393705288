import React, { useEffect, useState } from 'react';
import { get, set } from 'lodash';
import { PropTypes } from 'prop-types';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CustomTypography from '../../../../components/CustomTypography';
import { generateUniqueId } from '../../../../utils/helper';
import TextInput from '../../../../components/TextInput';
import ThemeButton from '../../../../components/ThemeButton';
import './styles.scss';
import searchIcon from '../../../../assets/images/common/search.svg';
import GroupCard from './GroupCard';
import ActionModal from '../../../../components/ActionModal';
import GroupModel from './NewGroupModel';
import CustomDialogBox from '../../../../components/CustomDialogBox';
import { useDispatch } from 'react-redux';
import { getCalculations } from '../../../../redux/slice/model';
import { useNotification } from '../../../../utils/NotificationProvider';

const Calculations = ({
  modelData,
  setFormData,
  setValidationRules,
  validationRules,
  formData,
  modelId,
}) => {
  const showNotification = useNotification();
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [group, setGroup] = useState([]);
  const [, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [addGroupModel, setAddGroupModel] = useState(false);
  const [newGroupState, setNewGroupState] = useState({
    groupName: '',
    groupType: null,
  });
  const [confirmDeleteGroupModel, setConfirmDeleteGroupModel] = useState({
    id: '',
    show: false,
  });
  const [confirmDeleteRowModel, setConfirmDeleteRowModel] = useState({
    index: '',
    id: '',
    show: false,
  });
  const workloads = get(modelData, 'workgroup.workloads');
  const dispatch = useDispatch();

  const removeGroup = () => {
    let newGroupData = group.filter(
      (item) => item._id !== confirmDeleteGroupModel.id
    );
    newGroupData = newGroupData.map(({ rows, ...item }, index) => {
      let letter = String.fromCharCode(65 + index);
      return {
        ...item,
        letter,
        rows: rows.map((row, i) => {
          if (row.type === 'subtotal') {
            return {
              ...row,
              rowId: `${letter}${i + 1}`,
              formula: {
                ...row.formula,
                variable: row.formula.variable?.map((variable, varIndex) => ({
                  ...variable,
                  source: {
                    ...variable.source,
                    path: ['REFERENCE', `${letter}${varIndex + 1}`],
                  },
                })),
              },
            };
          } else {
            return {
              ...row,
              rowId: `${letter}${i + 1}`,
            };
          }
        }),
      };
    });
    setGroup([...newGroupData]);
    setSearchQuery('');
    handleCloseModel();
  };

  const fetchCalcualationData = () => {
    setIsLoading(true);
    dispatch(
      getCalculations({
        url: `/calculations/getBymodelId/${modelId}`,
        method: 'GET',
        success: (res) => {
          if (get(res, 'data.groups') && get(res, 'data.groups').length > 0) {
            let groupData = get(res, 'data.groups', []);
            groupData = groupData?.map((item) => {
              let taskOptions = [];
              workloads?.forEach((workload) => {
                if (workload?._id === item.itemId) {
                  workload?.tasks?.forEach((task) => {
                    taskOptions = [
                      ...taskOptions,
                      { label: task.name, value: task._id },
                    ];
                  });
                }
              });
              return {
                ...item,
                fold: true,
                taskOptions: taskOptions,
              };
            });
            setGroup([...groupData]);
          }
          setIsLoading(false);
        },
        fail: (err) => {
          console.log('calendar error', err);
          setIsLoading(false);
        },
      })
    );
  };

  const addGroup = () => {
    if (
      group.some(
        (obj) =>
          obj.name.toLowerCase() === newGroupState.groupName.toLowerCase()
      )
    ) {
      showNotification(
        'error',
        `The group with ${newGroupState.groupName} name already exist`
      );
      return;
    }

    const lastGroupValues = group[group.length - 1];
    const letter = lastGroupValues
      ? String.fromCharCode(lastGroupValues?.letter.charCodeAt(0) + 1)
      : 'A';

    let newGroupData = {
      _id: generateUniqueId(),
      type: newGroupState.groupType,
      name: newGroupState.groupName,
      letter,
      itemId: newGroupState.workload,
      rows: [],
      fold: false
    };
    if (newGroupData.type === 'WORKLOAD') {
      let workloadTasksOption = [];
      workloads.forEach((item) => {
        if (item.name === newGroupData.name) {
          item.tasks.forEach((task) => {
            workloadTasksOption = [
              ...workloadTasksOption,
              { label: task.name, value: task._id },
            ];
          });
        }
      });
      newGroupData.taskOptions = workloadTasksOption;
    }
    if (newGroupState?.tasks?.length > 0) {
      newGroupState?.tasks?.forEach((item, index) => {
        let taskId = '';
        workloads.forEach((workload) => {
          if (workload.name === newGroupData.name) {
            workload.tasks.forEach((task) => {
              if (item === task.name) {
                taskId = task._id;
              }
            });
          }
        });
        newGroupData.rows = [
          ...newGroupData.rows,
          {
            _id: generateUniqueId(),
            name: item,
            rowId: `${letter}${index + 1}`,
            outputType: 'NUMBER',
            fineTuning: false,
            itemId: taskId,
            formula: {},
          },
        ];
      });
    }
    newGroupData.rows = [
      ...newGroupData.rows,
      {
        _id: generateUniqueId(),
        name: 'Group Subtotal',
        rowId: `${letter}${newGroupData.rows.length + 1}`,
        type: 'subtotal',
        fineTuning: false,
        outputType: 'NUMBER',
        formula: {},
      },
    ];
    // Add rows to Group sub total
    if (newGroupData.rows.length > 1) {
      let formula = {
        type: 'ADD',
        variable: [],
      };
      newGroupData.rows.forEach((item) => {
        if (item.type !== 'subtotal') {
          formula.variable = [
            ...formula.variable,
            {
              type: 'DIRECT_VALUE',
              source: {
                type: 'REFERENCE',
                path: ['REFERENCE', item.rowId],
              },
            },
          ];
        }
      });
      newGroupData.rows[newGroupData.rows.length - 1].formula = formula;
    }
    setGroup((prev) => [...prev, newGroupData]);
    setSearchQuery('');
    setNewGroupState({
      groupName: '',
      groupType: null,
    });
    setAddGroupModel(!addGroupModel);
  };

  const toggleAddGroupModel = () => {
    setAddGroupModel(!addGroupModel);
    setNewGroupState({
      groupName: '',
      groupType: null,
    });
  };

  const handleRemoveGroup = (data) => {
    setConfirmDeleteGroupModel({
      show: true,
      id: data._id,
    });
  };

  const removeRow = () => {
    const groupData = group;
    let rowsData = groupData[confirmDeleteRowModel.index].rows.filter(
      (row) => confirmDeleteRowModel.id !== row._id
    );
    rowsData?.forEach((item, i) => {
      item.rowId = `${groupData[confirmDeleteRowModel.index].letter}${i + 1}`;
    });
    // Add new row to Group sub total
    let formula = {
      type: 'ADD',
      variable: [],
    };
    rowsData.forEach((item) => {
      if (item.type !== 'subtotal') {
        formula.variable = [
          ...formula.variable,
          {
            type: 'DIRECT_VALUE',
            source: {
              type: 'REFERENCE',
              path: ['REFERENCE', item.rowId],
            },
          },
        ];
      }
    });
    rowsData[rowsData.length - 1].formula = formula;
    set(groupData, `[${confirmDeleteRowModel.index}].rows`, rowsData);
    setGroup([...groupData]);
    handleCloseModel();
  };

  const handleRemoveRow = (index, id) => {
    setConfirmDeleteRowModel({
      index: index,
      id: id,
      show: true,
    });
  };

  const handleCloseModel = () => {
    setConfirmDeleteGroupModel({
      show: false,
      id: '',
    });
    setConfirmDeleteRowModel({
      index: '',
      show: false,
      id: '',
    });
  };

  const actionButtons = [
    { title: 'Cancel', variant: 'outlined', onClick: toggleAddGroupModel },
    {
      title: 'Add Group',
      variant: 'contained',
      onClick: addGroup,
      isDisabled:
        !newGroupState.groupType ||
        (newGroupState.groupType === 'WORKLOAD' && !newGroupState?.workload)
          ? true
          : false,
    },
  ];
  const confirmDeleteGroupActionButtons = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseModel },
    {
      title: 'Delete',
      variant: 'contained',
      color: 'danger',
      onClick: removeGroup,
    },
  ];
  const confirmDeleteRowActionButtons = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseModel },
    {
      title: 'Delete',
      variant: 'contained',
      color: 'danger',
      onClick: removeRow,
    },
  ];

  useEffect(() => {
    setValidationRules({
      ...validationRules,
      calculation: [],
    });
    if(formData?.calculation){
      setGroup(formData?.calculation?.groups)
    }
  }, []);

  useEffect(() => {
    if(!formData?.calculation){
      fetchCalcualationData();
    }
  }, [modelId]);

  useEffect(() => {
    if (searchQuery) {
      let groupData = group.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredRecords([...groupData]);
    } else {
      setFilteredRecords([...group]);
    }
  }, [searchQuery]);

  useEffect(() => {
    setFilteredRecords(group);
    setFormData({
      ...formData,
      calculation: { groups: group },
    });
  }, [group]);

  return (
    <div className="calculation-step-wrapper">
      <div className="form-title">
        <CustomTypography variant="headerh3" text="Calculations" />
        <div className="flex-container">
          <TextInput
            placeholder="Search Calculation"
            sizeSmall
            variant="outlined"
            onInputChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            startAdornment={<img src={searchIcon} alt="Search Icon" />}
          />
          <ThemeButton
            text="New Group"
            variant="outlined"
            onClick={toggleAddGroupModel}
            startIcon={<AddRoundedIcon color="primary" />}
          />
        </div>
      </div>

      {group?.length > 0 && (
        <GroupCard
          data={[group, setGroup]}
          filteredRecords={filteredRecords}
          removeGroup={handleRemoveGroup}
          handleRemoveRow={handleRemoveRow}
          modelData={modelData}
        />
      )}
      <ActionModal
        open={addGroupModel}
        close={toggleAddGroupModel}
        component={
          <GroupModel
            newGroup={[newGroupState, setNewGroupState]}
            workloads={workloads}
          />
        }
        actionButtons={actionButtons}
        title="New Group"
        width="40%"
        className="new-user-modal"
      />
      <CustomDialogBox
        open={confirmDeleteGroupModel.show}
        onClose={handleCloseModel}
        title={`Delete group`}
        description={`Are you sure you want to delete group?`}
        actions={confirmDeleteGroupActionButtons}
      />
      <CustomDialogBox
        open={confirmDeleteRowModel.show}
        onClose={handleCloseModel}
        title={`Delete row`}
        description={`Are you sure you want to delete row?`}
        actions={confirmDeleteRowActionButtons}
      />
    </div>
  );
};

export default Calculations;

Calculations.prototype = {
  modelData: PropTypes.object,
  setFormData: PropTypes.func,
  setValidationRules: PropTypes.func,
  validationRules: PropTypes.object,
  formData: PropTypes.object,
};
