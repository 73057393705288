import React, { useEffect, useState } from 'react';
import CustomTypography from '../../../../components/CustomTypography';
import ThemeButton from '../../../../components/ThemeButton';
import AddWorkgroup from './AddWorkgroup';
import ActionModal from '../../../../components/ActionModal';
import './styles.scss';
import { capitalize } from '@mui/material';
import edit from '../../../../assets/images/common/edit.svg';
import trash from '../../../../assets/images/common/trash.svg';
import CustomDialogBox from '../../../../components/CustomDialogBox';
import { required, validateField } from '../../../../utils/Validator';
import { generateUniqueId, validateInput } from '../../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getTasks, getWorkgroups } from '../../../../redux/slice/model';
import { get } from 'lodash';
import typography from '../../../../theme/typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Loading from '../../../../utils/loading';

const Workgroup = ({ modelId, formData, setFormData, completedSteps, loading }) => {
  const [isWorkgroupModal, setIsWorkgroupModal] = useState(false);
  const [allTasks, setAllTasks] = useState([])
  const [isDeleteAlert, setIsDeleteAlert] = useState(false);
  const [editWorkgroup, setEditWorkgroup] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [workgroups, setWorkgroups] = useState([]);
  const [prevData, setPrevData] = useState([])
  const [errors, setErrors] = useState({});
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [hoveredColumn, setHoveredColumn] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newWorkgroup, setNewWorkgroup] = useState({
    workload: '',
    tasks: [],
    tasksList: [],
  });
  const isStepCompleted = get(completedSteps, 'workgroup', false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchTasks();
  }, [modelId]);
 
  useEffect(() => {
    if (isStepCompleted){
      fetchWorkgroups();
    }
  }, [modelId])

  useEffect(() => {
    setFormData({
      ...formData,
      workgroupManagement : {
        workgroups: workgroups,
        prevData: prevData
      }
    });
  }, [workgroups, prevData]);

  const workloadTastValidation = (message) => (value) => {
    if (!value || value.length === 0){
     return message
    } 
 }

 const modalRules = [
  required('Input is required'),
  validateInput('Input is required'),
];

const duplicateWorkgroupRule = (message, isEditWorkgroup = false, currentWorkgroupId = null) => (value) => {
  const workgroupList = workgroups
    .filter((item) => !isEditWorkgroup || item.id !== currentWorkgroupId)
    .map((r) => r.workload);
  if (workgroupList.some((wg) => value?.toLowerCase() === wg?.toLowerCase())) {
    return message;
  }
};

  const fetchWorkgroups = () => {
    setIsLoading(true);
    dispatch(getWorkgroups({
      url: `/workgroups/${modelId}`,
      method: 'GET',
      navigate,
      success: (res) => {
        if (get(res, 'data.workloads') && get(res, 'data.workloads').length > 0){
          const rows = get(res, 'data.workloads', []).map((item) => {
            const { _id, name, tasks} = item;
            return { 
              workload: name,
              id: _id,
              tasks: tasks.map((item) => {
                return {
                  label: item.name,
                  value: item._id
                }
              }),
              tasksList: tasks.map((item) => item.name)
            }
          });
          setWorkgroups([...rows]);
          setPrevData(JSON.parse(JSON.stringify(rows)));
        }
        setIsLoading(false);
      },
      fail: (err) => {
        console.log('workgroups error', err);
        setIsLoading(false);
      },
    }))
  }

  const fetchTasks = () => {
    setIsLoading(true);
    dispatch(getTasks({
      url: `/tasks/getBymodelId/${modelId}`,
      method: 'GET',
      navigate,
      success: (res) => {
        if (get(res, 'data') && get(res, 'data').length > 0){
          const rows = get(res, 'data', []).map((item) => {
            const { _id, name, } = item;
            return { 
              label: name,
              value: _id,  
            }
          });
          setAllTasks([...rows]);
        }
        setIsLoading(false);
      },
      fail: (err) => {
        console.log('tasks error', err);
        setIsLoading(false);
      },
    }))
  }

  const handleCloseWorkloadModal = () => {
    setIsWorkgroupModal(false);
    setNewWorkgroup({
      workload: '',
      tasks: [],
    });
    setEditWorkgroup(false);
    setEditIndex(null);
    setErrors({});
  };

  const handleSaveWorkload = () => {
    let workloadRules = {
      tasks: [workloadTastValidation('Task is required')]
    }
    const errorMsg = 'Workgroup with same name already exists'
    if (editWorkgroup) {
      workloadRules.workload = [
        ...modalRules,
        duplicateWorkgroupRule(errorMsg, true, newWorkgroup.id),
      ];
    } else {
      workloadRules.workload = [
        ...modalRules,
        duplicateWorkgroupRule(errorMsg),
      ];
    }
    if (!validateField(newWorkgroup, workloadRules , setErrors)) return;
    if (editWorkgroup) {
      setWorkgroups((prevWorkgroups) =>
        prevWorkgroups.map((workgroup, index) =>
          index === editIndex ? newWorkgroup : workgroup
        )
      );
    } else {
      setWorkgroups((prevWorkgroups) => [...prevWorkgroups, {...newWorkgroup, id: generateUniqueId()}]);
    }
    handleCloseWorkloadModal();
    setEditWorkgroup(false);
    setEditIndex(null);
    setErrors({});
  };

  const handleClose = () => {
    setIsDeleteAlert(false);
    setEditIndex(null);
  };

  const onDelete = () => {
    setWorkgroups((prevWorkgroups) =>
      prevWorkgroups.filter((_, index) => index !== editIndex)
    );
    handleClose();
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    setWorkgroups((prevWorkgroups) =>
      [...prevWorkgroups].sort((a, b) => {
        if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
        if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
        return 0;
      })
    );
  };

  const workloadActionBtns = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseWorkloadModal },
    {
      title: 'Save',
      variant: 'contained',
      onClick: handleSaveWorkload,
    },
  ];

  const actions = [
    { title: 'Cancel', variant: 'outlined', onClick: handleClose },
    {
      title: 'Delete workgroup',
      variant: 'contained',
      onClick: onDelete,
      color: 'danger',
    },
  ];

  const handleUpdateWorkload = (i) => { 
    setEditIndex(i);
    setEditWorkgroup(true);
    setIsWorkgroupModal(true);
    let newObj = {
      workload: workgroups[i].workload,
      tasks: allTasks.filter(obj1 =>
        workgroups[i].tasks.some(obj2 => obj1.label === obj2.label && obj1.value === obj2.value)
    ),
      tasksList: workgroups[i].tasksList,
    }
    if (workgroups[i].id){
      newObj.id = workgroups[i].id
    }
    setNewWorkgroup(newObj);
  };

  const handleDelete = (i) => {
    setEditIndex(i);
    setIsDeleteAlert(true);
  };

  return (
    <>
    {(loading || isLoading) ? <Loading /> : <>
      <div className="step-header flex-container" style={{paddingBottom: '20px'}}>
        <CustomTypography variant="headerh3" text="Workgroup" />
        <ThemeButton
          text="Add Workgroup"
          variant="outlined"
          onClick={() => setIsWorkgroupModal(true)}
        />
      </div>
      <div className="workgroup-container">
        <div className="workgroup-header d-flex">
          <div 
           className='column-label' 
           style={{width: '25%'}} 
           onClick={() => handleSort('workload')} 
           onMouseOver={() => setHoveredColumn('workload')} 
           onMouseOut={() => setHoveredColumn(null)}>
            <CustomTypography text="WORKGROUP" style={{ ...typography.overline }} />
            {(hoveredColumn === 'workload' || sortConfig.key === 'workload') && (
              sortConfig.direction === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
            )}
          </div>
          <div 
           className='column-label' 
           style={{width: '75%'}} 
           onClick={() => handleSort('tasksList')} 
           onMouseOver={() => setHoveredColumn('tasksList')} 
           onMouseOut={() => setHoveredColumn(null)}>
            <CustomTypography text="TASKS" style={{ ...typography.overline }} />
            {(hoveredColumn === 'tasksList' || sortConfig.key === 'tasksList') && (
              sortConfig.direction === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
            )}
          </div>
        </div>
        <div className="workgroup-list-container">
          {workgroups && workgroups.length > 0
            ? workgroups.map((item, i) => {
                return (
                  <div className="workgroup-list d-flex" key={i}>
                    <CustomTypography
                      text={capitalize(item.workload)}
                      style={{ width: '25%', ...typography.subtitle2 }}
                    />
                    <CustomTypography
                      text={item?.tasksList?.join(', ')}
                      style={{ width: '70%', ...typography.subtitle2 }}
                    />
                    <button
                      type="button"
                      className="icon-btn btn-secondary"
                      onClick={() => handleUpdateWorkload(i)}
                    >
                      <img src={edit} alt="edit" />
                    </button>
                    <button
                      type="button"
                      className="icon-btn btn-secondary"
                      onClick={() => handleDelete(i)}
                    >
                      <img src={trash} alt="trash" />
                    </button>
                  </div>
                );
              })
            : 
            <CustomTypography
              text={'No groups found'}
              style={{ marginTop: '4px', paddingLeft: '16px', ...typography.subtitle2 }}
            />}
        </div>
      </div>
      <ActionModal
        open={isWorkgroupModal}
        close={handleCloseWorkloadModal}
        component={
          <AddWorkgroup
            newWorkgroup={newWorkgroup}
            setNewWorkgroup={setNewWorkgroup}
            errors={errors}
            allTasks={allTasks}
          />
        }
        actionButtons={workloadActionBtns}
        title={`${editWorkgroup ? 'Edit': 'Add new'} workgroup`}
        className="upload-model"
      />
      <CustomDialogBox
        open={isDeleteAlert}
        onClose={handleClose}
        title="Delete Workgroup"
        description="Are you sure you want to delete this workgroup?"
        actions={actions}
      />
    </>}
    </>
  );
};

export default Workgroup;
