import React, { useEffect, useState } from 'react';
import CustomTypography from '../../../../components/CustomTypography';
import ThemeButton from '../../../../components/ThemeButton';
import { get } from 'lodash';
import EditableTable from '../../../../components/DataGrid/EditableTable';
import ActionModal from '../../../../components/ActionModal';
import AddCalendarItem from './AddCalendarItem';
import { required, validateField } from '../../../../utils/Validator';
import { generateUniqueId, validateInput } from '../../../../utils/helper';
import { useDispatch } from 'react-redux';
import { getCalendars } from '../../../../redux/slice/model';
import { useNavigate } from 'react-router-dom';
import CustomDialogBox from '../../../../components/CustomDialogBox';
import plus from '../../../../assets/images/common/plus.svg';

const Calendar = ({
  formData,
  loading,
  setFormData,
  modelId,
  completedSteps,
  modelData
}) => {
  const [calendarData, setCalendarData] = useState([]);
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const budgetType = get(modelData, 'budgetType');
  const modelWeeks = get(modelData, 'numberOfSplit');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [newItem, setNewItem] = useState({});
  const [errors, setErrors] = useState({});
  const [prevData, setPrevData] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [rowId, setRowId] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const count = budgetType === 'weekly' ? modelWeeks : 365;
  const isStepCompleted = get(completedSteps, 'calendar', false);

  useEffect(() => {
    setCalendarData([]);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      calendar: {
        data: calendarData,
        prevData: prevData,
      },
    });
  }, [calendarData, prevData]);

  const validationRules = [
    required('Input is required'),
    validateInput('Input is required'),
  ];

  const duplicateItemRule = () => (value) => {
    const calendarItems = calendarData.map((item) => item.name);
    if (calendarItems.some((item) => value?.toLowerCase() === item?.toLowerCase())) {
      return 'Item with same name already exists';
    }
  };

  const booleanType = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
  ];

  const onActionHandle = (index, row, type) => {
    if (type === 'delete') {
      setRowId(row.id);
      setIsAlert(true);
    }
  };

  const columns = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'WEEK NUMBER',
      sortable: true,
      searchFilter: true,
      valueType: 'array',
      filterType: 'checkbox',
      type: 'inputOnClick',
      width: '10%',
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: '',
      sortable: true,
      searchFilter: true,
      valueType: 'array',
      filterType: 'checkbox',
      width: '10%',
    },
    {
      id: 'button',
      numeric: true,
      disablePadding: false,
      label: '',
      sortable: false,
      type: 'buttons',
      moreOptions: {
        buttonClick: onActionHandle,
        buttons: [{ title: 'Delete' }],
      },
      width: '5%',
    },
  ];

  const addBudgetColumns = () => {
    let extraColumns = [];

    for (let i = 1; i <= count; i++) {
      extraColumns.push({
        id: `wd-${i}`,
        label: `${i}`,
        numeric: true,
        disablePadding: false,
        sortable: true,
        width: '50px',
        type: 'inputOnClick',
        options: booleanType,
      });
    }
    setUpdatedColumns([...columns, ...extraColumns]);
  };

  useEffect(() => {
    addBudgetColumns();
  }, [budgetType, newItem.type]);

  const fetchCalendarData = () => {
    setIsLoading(true);
    dispatch(
      getCalendars({
        url: `/calendars/getBymodelId/${modelId}`,
        method: 'GET',
        navigate,
        success: (res) => {
          if (get(res, 'data.rows') && get(res, 'data.rows').length > 0) {
            const calendarRows = get(res, 'data.rows', []);
            const rows = calendarRows.map((item) => {
              const { _id, name, type, values } = item;
              let transformmedData = {
                id: _id,
                name,
                type,
              };
              const inputType = type === 'boolean' ? 'dropdown' : 'input';
              const cellType =
                type === 'number' || type === 'percent' ? 'number' : 'text';

              values.forEach((value, index) => {
                transformmedData[`wd-${index + 1}`] =
                  type === 'boolean'
                    ? booleanType.find((item) => item.value === value)
                    : value;

                transformmedData.inputType = inputType;
                if (type !== 'boolean') {
                  transformmedData.cellType = cellType;
                }
              });
              return transformmedData;
            });
            setCalendarData([...rows]);
            setPrevData(JSON.parse(JSON.stringify(rows)));
          }
          setIsLoading(false);
        },
        fail: (err) => {
          console.log('calendar error', err);
          setIsLoading(false);
        },
      })
    );
  };
  
  useEffect(() => {
    if (isStepCompleted) {
      fetchCalendarData();
    }
  }, [isStepCompleted]);

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setNewItem({});
    setErrors({});
  };

  const handleSaveItem = () => {
    const addItemRules = {
      name: [...validationRules, duplicateItemRule()],
      value: validationRules,
      type: validationRules,
    };
    if (!validateField(newItem, addItemRules, setErrors)) return;
    const budgetFields = {};
    for (let i = 1; i <= count; i++) {
      if (newItem.type === 'boolean') {
        budgetFields[`wd-${i}`] = booleanType.find(
          (item) => item.value === newItem.value
        );
      } else {
        budgetFields[`wd-${i}`] = newItem.value;
      }
    };
    const newRow = {
      id: generateUniqueId(),
      name: newItem.name,
      type: newItem.type,
      inputType: newItem.type === 'boolean' ? 'dropdown' : 'input',
      cellType: (newItem.type === 'number' || newItem.type === 'percent') ? 'number' : 'text',
      ...budgetFields,
    };

    setCalendarData((prev) => [...prev, newRow]);
    handleCloseModal();
  };

  const handleUpdateDetails = (updatedDetails) => {
    const rows = updatedDetails
      ? JSON.parse(JSON.stringify(updatedDetails))
      : [];
    setCalendarData([...rows]);
  };

  const handleCloseAlert = () => {
    setIsAlert(false);
  };

  const handleDelete = () => {
    setCalendarData((prevRows) => prevRows.filter((row) => row.id !== rowId));
    handleCloseAlert();
  };
  
  const addItemActionBtns = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseModal },
    { title: 'Save', variant: 'contained', onClick: handleSaveItem },
  ];
  
  const deleteActions = [
    { title: 'Cancel', variant: 'outlined', onClick: handleCloseAlert },
    {
      title: 'Delete',
      variant: 'contained',
      onClick: handleDelete,
      color: 'danger',
    },
  ];

  return (
    <div style={{ height: '100%' }}>
      <div className="form-title flex-container">
        <CustomTypography variant="headerh3" text="Calendar" />
        <ThemeButton
          text="Add item"
          variant="outlined"
          onClick={handleOpenModal}
          startIcon={<img src={plus} alt="plus" />}
        />
      </div>
      <div className="driver-table">
        <EditableTable
          columns={updatedColumns}
          rows={calendarData}
          data={calendarData}
          tableOf={['calendar']}
          showStatusTabs={false}
          defaultOrderBy="calories"
          defaultOrder="asc"
          updateFilteredRows={(updatedDetails) =>
            handleUpdateDetails(updatedDetails.data)
          }
          showTableHeader={false}
          loading={loading || isLoading}
          tableHeight={'calc(100% - 20px)'}
        />
      </div>
      <ActionModal
        open={isOpenModal}
        close={handleCloseModal}
        actionButtons={addItemActionBtns}
        component={
          <AddCalendarItem
            newItem={newItem}
            setNewItem={setNewItem}
            errors={errors}
            booleanType={booleanType}
          />
        }
        title="Calendar data"
        className="upload-model-wrapper"
        width="25%"
      />
      <CustomDialogBox
        open={isAlert}
        onClose={handleCloseAlert}
        title="Delete Calendar data"
        description="Are you sure you want to delete this calendar data?"
        actions={deleteActions}
      />
    </div>
  );
};

export default Calendar;