import React, { useEffect, useState } from 'react';
import './style.scss';
import ThemeButton from '../../components/ThemeButton';
import CustomAvatar from '../../components/CustomAvatar';
import CustomTypography from '../../components/CustomTypography';
import BasicInfo from './basicInfo';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import GeneralSettigns from './generalSettings';
import { fetchProfileDetails, updateProfile } from '../../redux/slice/profile';
import { useDispatch } from 'react-redux';
import { appConfig } from '../../config/appConfig';
import { timeStandardOptions } from './constant';
import Loader from '../../components/Loader';
import { useNotification } from '../../utils/NotificationProvider';
import { useNavigate } from 'react-router-dom';
import { required, validateField } from '../../utils/Validator';
import { isPhoneValid, validateInput } from '../../utils/helper';

const Profile = () => {
  const userName = `${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}`;
  const userInitial = userName?.charAt(0);
  const [profileImg, setProfileImg] = useState(null);
  const [values, setValues] = useState({});
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [profileDetails, setProfileDetails] = useState();
  const showNotification = useNotification();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validationRules = {
    firstName: [
      required('First Name is required'),
      validateInput('First Name is required'),
    ],
    lastName: [
      required('Last Name is required'),
      validateInput('Last Name is required'),
    ],
    phone: [
      required('Phone number is required'),
      isPhoneValid('Invalid phone number', 'US'),
    ],
  };
  const [isImageAvailable, setIsImageAvailable] = useState(null);

  useEffect(() => {
    const checkImage = async () => {
      try {
        const response = await fetch(profileImg, { method: 'HEAD' });
        setIsImageAvailable(response.ok);
      } catch (error) {
        setIsImageAvailable(false);
      }
    };

    checkImage();
  }, [profileImg]);

  const handleChange = (name, val, checkedVal) => {
    if (checkedVal) {
      setValues({
        ...values,
        [name]: {
          val: checkedVal,
          additionalFieldVal: val,
        },
      });
    } else {
      if (name === 'timeZone' && parseInt(val) === 2) {
        setValues({
          ...values,
          [name]: {
            val: val,
            additionalFieldVal: 'BST',
          },
        });
      } else if (name === 'timeZone' && parseInt(val) === 3) {
        setValues({
          ...values,
          [name]: {
            val: val,
            additionalFieldVal: null,
          },
        });
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: '',
        }));
        setValues({
          ...values,
          [name]: val,
        });
      }
    }
  };

  const handlePhoneChange = (val, name) => {
    setValues({
      ...values,
      [name]: val,
    });
  };

  const isFile = (value) => {
    return value instanceof File; // Check if the input is a File object
  };

  const getProfileDetails = async () => {
    setLoader(true);
    await dispatch(
      fetchProfileDetails({
        url: '/users/profile',
        method: 'GET',
        success: (res) => {
          if (res && res.data) {
            setProfileDetails(res.data);
            setLoader(false);
          } else {
            setProfileDetails({});
            setLoader(false);
          }
        },
        fail: (err) => {
          console.log('err', err);
        },
      })
    );
  };

  const handleSuccess = (message) => {
    showNotification('success', message);
  };

  const handleFailure = (message) => {
    const msg = message || 'Something went wrong';
    showNotification('error', msg);
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  useEffect(() => {
    if (profileDetails) {
      const {
        firstName,
        lastName,
        email,
        phone,
        dateFormat,
        timeFormat,
        companies,
        profileImage,
        timeZone,
      } = profileDetails;
      setValues({
        firstName,
        email,
        lastName,
        phone,
        timeFormat,
        dateFormat,
        companies,
      });
      if (profileImage) {
        const profileUrl = `${appConfig.url}${profileImage.replace(/\\/g, '/')}`;
        setProfileImg(profileUrl);
      }
      if (timeZone) {
        let timeZoneValue;
        for (let i = 0; i < timeStandardOptions.length; i++) {
          if (timeStandardOptions[i].value === timeZone) {
            timeZoneValue = timeZone;
            i = timeStandardOptions.length;
          } else {
            if (timeStandardOptions[i].options) {
              timeStandardOptions[i].options?.map((opt) => {
                if (opt.value === timeZone) {
                  const val = { val: 2, additionalFieldVal: timeZone };
                  timeZoneValue = val;
                  i = timeStandardOptions.length;
                } else if (!timeZoneValue) {
                  const val = { val: 3, additionalFieldVal: timeZone };
                  timeZoneValue = val;
                  i = timeStandardOptions.length;
                }
              });
            } else if (!timeZoneValue) {
              if (timeStandardOptions[i].additionalFieldType) {
                const val = { val: 3, additionalFieldVal: timeZone };
                timeZoneValue = val;
              }
            }
          }
        }
        setValues((prev) => ({ ...prev, timeZone: timeZoneValue }));
      }
    }
  }, [profileDetails]);

  const onSubmit = () => {
    if (!validateField(values, validationRules, setErrors, errors)) return;
    setLoader(true);
    const requestedPayload = {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      timeZone: values.timeZone.additionalFieldVal || values.timeZone,
      timeFormat: values.timeFormat,
      dateFormat: values.dateFormat,
    };
    if (isFile(profileImg)) {
      requestedPayload.profileImage = profileImg;
    }
    dispatch(
      updateProfile({
        url: `/users/profile`,
        method: 'PUT',
        navigate,
        contentType: 'multipart/form-data',
        data: requestedPayload,
        success: () => {
          handleSuccess('Profile updated successfully!');
          navigate('/');
          setLoader(false);
        },
        fail: (err) => {
          handleFailure(err.message);
          setLoader(false);
        },
      })
    );
  };

  return (
    <div className="profile-page-container">
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="profile-details d-flex">
            <div className="left-col">
              <div
                className="profile-image"
                style={{
                  ...(isImageAvailable && {backgroundImage: `url(${
                    typeof profileImg === 'string'
                      ? profileImg
                      : profileImg instanceof File || profileImg instanceof Blob
                        ? URL.createObjectURL(profileImg)
                        : ''
                  })`})
                }}
              >
                {!profileImg || !isImageAvailable && (
                  <CustomAvatar name={userInitial} color="#864293" />
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    setProfileImg(e.target.files[0]);
                  }}
                />
                <span>
                  <BorderColorOutlinedIcon />
                </span>
              </div>
              <div className="details">
                <p className="role-badge">
                  {
                    appConfig.userRoles.find(
                      (item) => profileDetails?.role === item.value
                    )?.label
                  }
                </p>
                <CustomTypography
                  variant="subtitleS1"
                  text={`${profileDetails?.firstName + ' ' + profileDetails?.lastName}`}
                />
                {profileDetails?.companies?.map((item) => (
                  <CustomTypography
                    key={item.name}
                    variant="subtitleS1"
                    text={item.name}
                  />
                ))}
              </div>
            </div>
            <div className="right-col">
              <BasicInfo
                values={values}
                errors={errors}
                handleChange={handleChange}
                handlePhoneChange={handlePhoneChange}
              />
              <GeneralSettigns values={values} handleChange={handleChange} />
            </div>
          </div>
          <div className="company-frame flex-container form-title">
            <ThemeButton
              text={'Save changes'}
              variant="contained"
              onClick={onSubmit}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Profile;
