import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import SelectDropdown from '../../../../components/SelectDropdown';
import { conditionalOperators, operators } from './constant';
import VariableDropdown from './VariableDropdown';
import TextInput from '../../../../components/TextInput';
import SubChildFormulaField from './SubChildFormulaField';
import { get } from 'lodash';

const ChildFormulaField = ({
  data,
  index,
  rowindex,
  modelData,
  variableIndex,
  handleOperatorChange,
  handleFreeInputChange,
  handleOpenMenu,
  handleCloseMenu,
  handleConditionalOperatorChange,
}) => {
  const [group] = data;

  const formula = get(group, `[${index}].rows[${rowindex}].formula`, {});

  const getParentVariable = () => {
    return formula.variable?.[variableIndex];
  };

  const getChildVariable = (childVariableIndex) => {
    return getParentVariable().variable?.[childVariableIndex];
  };

  return (
    <div className="formula-fields-wrapper">
      {getParentVariable()?.type && (
        <>
          {(getParentVariable()?.type === 'IF_BOOLEAN' ||
            getParentVariable()?.type === 'IF_COMPARE') && (
            <Box className="bg-green">IF</Box>
          )}
          {getChildVariable(0).type === 'DIRECT_VALUE' &&
          getChildVariable(0).source?.type === 'FREE' ? (
            <TextInput
              name="variable-field"
              value={getChildVariable(0).source?.path[1]}
              onInputChange={(e) =>
                handleFreeInputChange(e.target.value, 'child', variableIndex, 0)
              }
              type="number"
              className="child"
              sizeSmall
            />
          ) : [
              'ADD',
              'SUBTRACT',
              'MULTIPLY',
              'DIVIDE',
              'IF_BOOLEAN',
              'IF_COMPARE',
            ].includes(getChildVariable(0)?.type) ? (
            <Box className="variable-field child">
              <SubChildFormulaField
                data={data}
                index={index}
                rowindex={rowindex}
                handleConditionalOperatorChange={handleConditionalOperatorChange}
                modelData={modelData}
                variableIndex={variableIndex}
                childVariableIndex={0}
                varIndex="one"
                handleOperatorChange={handleOperatorChange}
                handleFreeInputChange={handleFreeInputChange}
                handleOpenMenu={handleOpenMenu}
                handleCloseMenu={handleCloseMenu}
              />
            </Box>
          ) : (
            <>
              <Box
                className="variable-field child"
                id="child_field_variable_one"
                aria-controls="child_menu_variable_one"
                onClick={(e) => handleOpenMenu(e, variableIndex, 'child', 0)}
              >
                {getChildVariable(0).type === 'DIRECT_VALUE' &&
                getChildVariable(0).source?.path?.length > 1
                  ? getChildVariable(0).source?.path[
                      getChildVariable(0)?.source?.path?.length - 1
                    ]?.label ||
                    getChildVariable(0).source?.path[
                      getChildVariable(0)?.source?.path?.length - 1
                    ]
                  : ''}
              </Box>
              <VariableDropdown
                handleCloseMenu={handleCloseMenu}
                type="child"
                parentVariableIndex={variableIndex}
                childVariableIndex={0}
                varIndex="one"
                className="menu-dropdown"
                data={data}
                index={index}
                rowindex={rowindex}
                anchorEl={getChildVariable(0).openMenu}
                open={Boolean(getChildVariable(0).openMenu)}
                modelData={modelData}
              />
            </>
          )}
        </>
      )}
      {getParentVariable()?.type !== 'IF_BOOLEAN' &&
        getParentVariable()?.type !== 'IF_COMPARE' && (
          <SelectDropdown
            classNameForFormControl={`custom-min-width ${getParentVariable()?.type && getParentVariable()?.type !== 'DIRECT_VALUE' ? 'shrink' : ''}`}
            items={operators}
            handleChange={(e) =>
              handleOperatorChange(e, index, rowindex, variableIndex, 0)
            }
            iconPosition="end"
            className="icon-dropdown"
            name="operator"
            value={getParentVariable()?.type}
            disabled={getParentVariable()?.type}
          />
        )}
      {getParentVariable()?.type === 'IF_COMPARE' ? (
        <SelectDropdown
          classNameForFormControl="shrink"
          items={conditionalOperators}
          handleChange={(e) => handleConditionalOperatorChange(e, 'child', variableIndex)}
          iconPosition="end"
          className="icon-dropdown"
          name="operator"
          value={getChildVariable(1)?.source?.path?.[1]}
        />
      ) : (
        ''
      )}
      {getParentVariable()?.type === 'IF_COMPARE' && (
        <>
          {getChildVariable(2).type === 'DIRECT_VALUE' &&
          getChildVariable(2).source?.type === 'FREE' ? (
            <TextInput
              name="variable-field"
              value={getChildVariable(2).source?.path[1]}
              onInputChange={(e) =>
                handleFreeInputChange(e.target.value, 'child', variableIndex, 2)
              }
              type="number"
              className="child"
              sizeSmall
            />
          ) : [
              'ADD',
              'SUBTRACT',
              'MULTIPLY',
              'DIVIDE',
              'IF_BOOLEAN',
              'IF_COMPARE',
            ].includes(getChildVariable(2)?.type) ? (
            <Box className="variable-field child">
              <SubChildFormulaField
                data={data}
                index={index}
                rowindex={rowindex}
                handleConditionalOperatorChange={handleConditionalOperatorChange}
                modelData={modelData}
                variableIndex={variableIndex}
                childVariableIndex={2}
                varIndex="three"
                handleOperatorChange={handleOperatorChange}
                handleFreeInputChange={handleFreeInputChange}
                handleOpenMenu={handleOpenMenu}
                handleCloseMenu={handleCloseMenu}
              />
            </Box>
          ) : (
            <>
              <Box
                className="variable-field child"
                id="child_field_variable_three"
                aria-controls="child_menu_variable_three"
                onClick={(e) => handleOpenMenu(e, variableIndex, 'child', 2)}
              >
                {getChildVariable(2).type === 'DIRECT_VALUE' &&
                getChildVariable(2).source?.path?.length > 1
                  ? getChildVariable(2).source?.path[
                      getChildVariable(2)?.source?.path?.length - 1
                    ]?.label ||
                    getChildVariable(2).source?.path[
                      getChildVariable(2)?.source?.path?.length - 1
                    ]
                  : ''}
              </Box>
              <VariableDropdown
                handleCloseMenu={handleCloseMenu}
                type="child"
                parentVariableIndex={variableIndex}
                childVariableIndex={2}
                varIndex="three"
                className="menu-dropdown"
                data={data}
                index={index}
                rowindex={rowindex}
                anchorEl={getChildVariable(2).openMenu}
                open={Boolean(getChildVariable(2).openMenu)}
                modelData={modelData}
              />
            </>
          )}
        </>
      )}
      {(getParentVariable()?.type === 'IF_BOOLEAN' ||
        getParentVariable()?.type === 'IF_COMPARE') && (
        <Box className="bg-green">then</Box>
      )}
      {getChildVariable(getParentVariable()?.type === 'IF_COMPARE' ? 3 : 1)
        .type === 'DIRECT_VALUE' &&
      getChildVariable(getParentVariable()?.type === 'IF_COMPARE' ? 3 : 1)
        .source?.type === 'FREE' ? (
        <TextInput
          name="variable-field"
          value={
            getChildVariable(getParentVariable()?.type === 'IF_COMPARE' ? 3 : 1)
              .source?.path[1]
          }
          onInputChange={(e) =>
            handleFreeInputChange(
              e.target.value,
              'child',
              variableIndex,
              getParentVariable()?.type === 'IF_COMPARE' ? 3 : 1
            )
          }
          type="number"
          className="child"
          sizeSmall
        />
      ) : [
          'ADD',
          'SUBTRACT',
          'MULTIPLY',
          'DIVIDE',
          'IF_BOOLEAN',
          'IF_COMPARE',
        ].includes(
          getChildVariable(getParentVariable()?.type === 'IF_COMPARE' ? 3 : 1)
            ?.type
        ) ? (
        <Box className="variable-field child">
          <SubChildFormulaField
            data={data}
            index={index}
            rowindex={rowindex}
            handleConditionalOperatorChange={handleConditionalOperatorChange}
            modelData={modelData}
            variableIndex={variableIndex}
            childVariableIndex={
              getParentVariable()?.type === 'IF_COMPARE' ? 3 : 1
            }
            varIndex={
              getParentVariable()?.type === 'IF_COMPARE' ? 'four' : 'two'
            }
            handleOperatorChange={handleOperatorChange}
            handleFreeInputChange={handleFreeInputChange}
            handleOpenMenu={handleOpenMenu}
            handleCloseMenu={handleCloseMenu}
          />
        </Box>
      ) : (
        <>
          <Box
            className="variable-field child"
            id={
              getParentVariable()?.type === 'IF_COMPARE'
                ? `child_field_variable_four`
                : `child_field_variable_two`
            }
            aria-controls={
              getParentVariable()?.type === 'IF_COMPARE'
                ? `child_menu_variable_four`
                : `child_menu_variable_two`
            }
            onClick={(e) =>
              handleOpenMenu(
                e,
                variableIndex,
                'child',
                getParentVariable()?.type === 'IF_COMPARE' ? 3 : 1
              )
            }
          >
            {getChildVariable(
              getParentVariable()?.type === 'IF_COMPARE' ? 3 : 1
            )?.type === 'DIRECT_VALUE' &&
            getChildVariable(getParentVariable()?.type === 'IF_COMPARE' ? 3 : 1)
              ?.source?.path?.length > 1
              ? getChildVariable(
                  getParentVariable()?.type === 'IF_COMPARE' ? 3 : 1
                ).source?.path[
                  getChildVariable(
                    getParentVariable()?.type === 'IF_COMPARE' ? 3 : 1
                  )?.source?.path?.length - 1
                ]?.label ||
                getChildVariable(
                  getParentVariable()?.type === 'IF_COMPARE' ? 3 : 1
                ).source?.path[
                  getChildVariable(
                    getParentVariable()?.type === 'IF_COMPARE' ? 3 : 1
                  )?.source?.path?.length - 1
                ]
              : ''}
          </Box>
          <VariableDropdown
            handleCloseMenu={handleCloseMenu}
            type="child"
            parentVariableIndex={variableIndex}
            childVariableIndex={
              getParentVariable()?.type === 'IF_COMPARE' ? 3 : 1
            }
            className="menu-dropdown"
            varIndex={
              getParentVariable()?.type === 'IF_COMPARE' ? 'four' : 'two'
            }
            data={data}
            index={index}
            rowindex={rowindex}
            anchorEl={
              getChildVariable(
                getParentVariable()?.type === 'IF_COMPARE' ? 3 : 1
              ).openMenu
            }
            open={Boolean(
              getChildVariable(
                getParentVariable()?.type === 'IF_COMPARE' ? 3 : 1
              ).openMenu
            )}
            modelData={modelData}
          />
        </>
      )}
      {(getParentVariable()?.type === 'IF_BOOLEAN' ||
        getParentVariable()?.type === 'IF_COMPARE') && (
        <Box className="bg-green">else</Box>
      )}
      {(getParentVariable()?.type === 'IF_BOOLEAN' ||
        getParentVariable()?.type === 'IF_COMPARE') &&
        (getChildVariable(getParentVariable()?.type === 'IF_COMPARE' ? 4 : 2)
          .source?.type === 'FREE' ? (
          <TextInput
            name="variable-field"
            value={
              getChildVariable(
                getParentVariable()?.type === 'IF_COMPARE' ? 4 : 2
              ).source?.path[1]
            }
            onInputChange={(e) =>
              handleFreeInputChange(
                e.target.value,
                'child',
                variableIndex,
                getParentVariable()?.type === 'IF_COMPARE' ? 4 : 2
              )
            }
            type="number"
            className="child"
            sizeSmall
          />
        ) : [
            'ADD',
            'SUBTRACT',
            'MULTIPLY',
            'DIVIDE',
            'IF_BOOLEAN',
            'IF_COMPARE',
          ].includes(
            getChildVariable(getParentVariable()?.type === 'IF_COMPARE' ? 4 : 2)
              ?.type
          ) ? (
          <Box className="variable-field child">
            <SubChildFormulaField
              data={data}
              index={index}
              rowindex={rowindex}
              handleConditionalOperatorChange={handleConditionalOperatorChange}
              modelData={modelData}
              variableIndex={variableIndex}
              childVariableIndex={
                getParentVariable()?.type === 'IF_COMPARE' ? 4 : 2
              }
              varIndex={
                getParentVariable()?.type === 'IF_COMPARE' ? 'five' : 'three'
              }
              handleOperatorChange={handleOperatorChange}
              handleFreeInputChange={handleFreeInputChange}
              handleOpenMenu={handleOpenMenu}
              handleCloseMenu={handleCloseMenu}
            />
          </Box>
        ) : (
          <>
            <Box
              className="variable-field child"
              id={
                getParentVariable()?.type === 'IF_COMPARE'
                  ? `child_field_variable_five`
                  : `child_field_variable_three`
              }
              aria-controls={
                getParentVariable()?.type === 'IF_COMPARE'
                  ? `child_menu_variable_five`
                  : `child_menu_variable_three`
              }
              onClick={(e) =>
                handleOpenMenu(
                  e,
                  variableIndex,
                  'child',
                  getParentVariable()?.type === 'IF_COMPARE' ? 4 : 2
                )
              }
            >
              {getChildVariable(
                getParentVariable()?.type === 'IF_COMPARE' ? 4 : 2
              )?.type === 'DIRECT_VALUE' &&
              getChildVariable(
                getParentVariable()?.type === 'IF_COMPARE' ? 4 : 2
              )?.source?.path?.length > 1
                ? getChildVariable(
                    getParentVariable()?.type === 'IF_COMPARE' ? 4 : 2
                  ).source?.path[
                    getChildVariable(
                      getParentVariable()?.type === 'IF_COMPARE' ? 4 : 2
                    )?.source?.path?.length - 1
                  ]?.label ||
                  getChildVariable(
                    getParentVariable()?.type === 'IF_COMPARE' ? 4 : 2
                  ).source?.path[
                    getChildVariable(
                      getParentVariable()?.type === 'IF_COMPARE' ? 4 : 2
                    )?.source?.path?.length - 1
                  ]
                : ''}
            </Box>
            <VariableDropdown
              handleCloseMenu={handleCloseMenu}
              type="child"
              parentVariableIndex={variableIndex}
              childVariableIndex={
                getParentVariable()?.type === 'IF_COMPARE' ? 4 : 2
              }
              className="menu-dropdown"
              varIndex={
                getParentVariable()?.type === 'IF_COMPARE' ? 'five' : 'three'
              }
              data={data}
              index={index}
              rowindex={rowindex}
              anchorEl={
                getChildVariable(
                  getParentVariable()?.type === 'IF_COMPARE' ? 4 : 2
                ).openMenu
              }
              open={Boolean(
                getChildVariable(
                  getParentVariable()?.type === 'IF_COMPARE' ? 4 : 2
                ).openMenu
              )}
              modelData={modelData}
            />
          </>
        ))}
    </div>
  );
};

export default ChildFormulaField;

ChildFormulaField.propTypes = {
  index: PropTypes.number,
  rowindex: PropTypes.number,
  data: PropTypes.array,
  modelData: PropTypes.object,
  variableIndex: PropTypes.number,
  handleOperatorChange: PropTypes.func,
  handleFreeInputChange: PropTypes.func,
  handleOpenMenu: PropTypes.func,
  handleCloseMenu: PropTypes.func,
  handleConditionalOperatorChange: PropTypes.func,
};
