import { Box } from '@mui/material';
import React from 'react';
import SelectDropdown from '../../../../components/SelectDropdown';
import { operators } from './constant';
import { get } from 'lodash';

const SubTotalFields = ({ groupItem, rowindex }) => {
  return (
    <div className="formula-fields-wrapper">
      {get(groupItem, `rows[${rowindex}].formula.variable`, []).map(
        (item, i) => (
          <>
            {i > 0 &&
              i <
                get(groupItem, `rows[${rowindex}].formula.variable`, [])
                  .length && (
                <SelectDropdown
                  classNameForFormControl={`custom-min-width shrink`}
                  items={operators}
                  iconPosition="end"
                  className="icon-dropdown"
                  name="operator"
                  value={'ADD'}
                  disabled={true}
                />
              )}
            <Box className="variable-field subtotal">{item?.source?.path?.[1]}</Box>
          </>
        )
      )}
    </div>
  );
};

export default SubTotalFields;
