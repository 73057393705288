import React from 'react';
import TextInput from '../../../../components/TextInput';
import CustomAutocomplete from '../../../../components/CustomAutocomplete';

const AddRole = ({ newRole, setNewRole, errors, storeList, isEditRole }) => {

  const handleChange = (name, value) => {
    setNewRole((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAutocomplete = (event, newValue) => {
    setNewRole((prevValues) => ({
      ...prevValues,
      stores: newValue,
    }));
  };

  return (
    <div className="pd-24 d-flex fd-column" style={{gap: '20px'}}>
      <TextInput
        label="Role name "
        name="roleName"
        value={newRole.roleName}
        onInputChange={(e) => handleChange(e.target.name, e.target.value)}
        helperText={errors.roleName}
      />
      <CustomAutocomplete
        name="stores"
        label="Location"
        value={newRole.stores}
        options={storeList}
        onChange={handleAutocomplete}
        width="100%"
        isMultiple={true}
      />
      {!isEditRole && <>
      <div className='d-flex g-8'>
      <TextInput
        label="Hours"
        name="hours"
        value={newRole.hours}
        type="number"
        onInputChange={(e) => handleChange(e.target.name, e.target.value)}
        helperText={errors.hours}
      />
      <TextInput
        label="Payrise week"
        name="payRiseWeek"
        value={newRole.payRiseWeek}
        type="number"
        onInputChange={(e) => handleChange(e.target.name, e.target.value)}
        helperText={errors.payRiseWeek}
      />
      </div>
      <div className='d-flex g-8'>
      <TextInput
        label="Payrise"
        name="payRise"
        value={newRole.payRise}
        type="number"
        onInputChange={(e) => handleChange(e.target.name, e.target.value)}
        helperText={errors.payRise}
      />
      <TextInput
        label="Pay pre payrise"
        name="payPrePayRise"
        value={newRole.payPrePayRise}
        type="number"
        onInputChange={(e) => handleChange(e.target.name, e.target.value)}
        helperText={errors.payPrePayRise}
      />
      </div>
      </>}
    </div>
  );
};

export default AddRole;
