import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { PropTypes } from 'prop-types';
import './styles.scss';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  specialOption: { 
    fontWeight: 'bold',
    color: '#864293'
  },
});

const SelectDropdown = ({
  handleChange,
  value = [],
  items = [],
  isMultiple,
  className,
  classNameForFormControl,
  iconPosition,
  label,
  name,
  minWidth,
  helperText,
  specialValue,
  onBlur,
  defaultOpen,
  onClose,
  disabled,
  menuClasses
}) => {
  const menuStyle = {
    PaperProps: {
      sx: {
        '& .MuiMenuItem-root': {
          fontSize: '14px',
        },
      },
    },
  };

 
  const classes = useStyles();
  return (
    <div className="select-dropdown">
      <FormControl
        sx={{ m: 1, minWidth: { minWidth }, margin: '6px' }}
        size="small"
        className={classNameForFormControl}
      >
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={value}
          size="small"
          onChange={handleChange}
          multiple={isMultiple}
          lable={label}
          inputProps={{ 'aria-label': 'Without label' }}
          className={className}
          name={name}
          MenuProps={menuStyle}
          onBlur={onBlur}
          defaultOpen={defaultOpen}
          onClose={onClose}
          disabled={disabled}
        >
          {items?.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
              onClick={item?.onClick}
              className={item.label === specialValue ? classes.specialOption : ''}
              disabled={item.value === 'no-data'}
            >
              {iconPosition === 'end' ? (
                <div className={`${menuClasses} menu-item`}>
                  {item.label}
                  {item.icon}
                </div>
              ) : (
                <>
                  {item.icon}
                  {item.label}
                </>
              )}
            </MenuItem>
          ))}
        </Select>

        {isMultiple && value && value?.length > 0 && (
          <div style={{ marginTop: '8px', display: 'flex', gap: '5px' }}>
            {value.map((value) => (
              <div key={value} className="selected-dropdown-value">
                {value}
              </div>
            ))}
          </div>
        )}
        {helperText && <span className="error-txt">{helperText}</span>}
      </FormControl>
    </div>
  );
};

SelectDropdown.propTypes = {
  text: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  minWidth: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  handleChange: PropTypes.func,
  isMultiple: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string.isRequired,
      icon: PropTypes.element,
    })
  ),
  variant: PropTypes.string,
  iconPosition: PropTypes.string,
  className: PropTypes.string,
  classNameForFormControl: PropTypes.string,
  menuClasses: PropTypes.string,
};

SelectDropdown.defaultProps = {
  isMultiple: false,
  defaultOpen: false,
  iconPosition: 'end',
  value: 'string',
  minWidth: '70px',
};

export default SelectDropdown;
