import { useEffect, useState } from 'react';
import CustomAvatar from '../components/CustomAvatar';
import './styles.scss';
import CustomTypography from '../components/CustomTypography';
import typography from '../theme/typography';
import ThemeButton from '../components/ThemeButton';
import { useNavigate } from 'react-router-dom';
import user from '../assets/images/login/user.svg';
import logout from '../assets/images/header/logout.svg';
import { Popover } from '@mui/material';
import CustomDialogBox from '../components/CustomDialogBox';
import { appConfig } from '../config/appConfig';
import { fetchProfileDetails } from '../redux/slice/profile';
import { useDispatch, useSelector } from 'react-redux';

const Header = () => {
  const userName = `${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}`;
  const userEmail = localStorage.getItem('userEmail');
  const userInitial = userName?.charAt(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [profileDetails, setProfileDetails] = useState();
  const [isAlert, setAlert] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileInfo = useSelector((state) => state.profile.data)
  const [isImageAvailable, setIsImageAvailable] = useState(null);
  
  useEffect(() => {
    const checkImage = async () => {
      try {
        const response = await fetch(`${appConfig.url}${profileDetails?.profileImage}`, { method: 'HEAD' });
        setIsImageAvailable(response.ok);
      } catch (error) {
        setIsImageAvailable(false);
      }
    };
    if(profileDetails?.profileImage){
      checkImage();
    }
  }, [profileDetails?.profileImage]);

  const handleClose = () => {
    setAlert(false);
  };

  const actions = [
    { title: 'Cancel', variant: 'outlined', onClick: handleClose },
    {
      title: 'Logout',
      variant: 'contained',
      color: 'primary',
      onClick: () => handleLogout(),
    },
  ];

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
    setAnchorEl(null);
  };

  const openProfile = Boolean(anchorEl);

  const getProfileDetails = () => {
      dispatch(fetchProfileDetails({
      url: '/users/profile',
      method: 'GET',
      success: (res) => {
        if(res?.data){
          setProfileDetails(res?.data)
        }
      },
      fail: (err) => {
          console.log("err", err)
      }}))
  }
  
  useEffect(()=> {
    if(!profileInfo.data || profileInfo?.data?.length === 0){
      getProfileDetails();
    }
  }, [profileInfo])

  return (
    <div className="header">
      <div className="header-container">
        <div className="customer-select"></div>
        <div className="profile-btn">
          <button
            className="icon-btn"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            {
              isImageAvailable ?
              <img src={`${appConfig.url}${profileDetails?.profileImage}`} />
              : 
              <CustomAvatar name={userInitial} color="#864293" />
            }
          </button>
          <Popover
            id="Profile-popup"
            open={openProfile}
            anchorEl={anchorEl}
            className="profile-popup"
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <div className="profile-container">
              <div className="d-flex-center user-details">
              {
                isImageAvailable ?
                <img className='profile-img' src={`${appConfig.url}${profileDetails?.profileImage}`} />
                : 
                <CustomAvatar name={userInitial} color="#864293" />
              }
                <div>
                  <CustomTypography
                    text={userName}
                    style={typography.subtitleS1}
                  />
                  <CustomTypography
                    text={userEmail}
                    style={typography.bodyB2}
                  />
                </div>
              </div>
              <div className="divider" />
              <div className="d-flex">
                <ThemeButton
                  text="View profile"
                  onClick={() => {
                    navigate('/profile');
                    setAnchorEl(null);
                  }}
                  startIcon={<img src={user} alt="user" />}
                />
              </div>
              <div className="divider" />
              <div className="d-flex">
                <ThemeButton
                  text="Log out"
                  onClick={() => setAlert(true)}
                  startIcon={<img src={logout} alt="user" />}
                />
              </div>
            </div>
          </Popover>
          <CustomDialogBox
            open={isAlert}
            onClose={handleClose}
            title="Confirm Logout"
            description="Are you sure you want to Logout?"
            actions={actions}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
